* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

.App {
  background-color: #923cb5;
  background-image: linear-gradient(147deg, #923cb5 0%, #000000 90%);
  background-size: cover;

  text-align: center;
  min-height: 100vh;
  color: #e4a9a9;
  padding: 0rem 2.5rem;
}

.header {
  padding-top: 10rem;
  height: 100vh;
}

.header a {
  font-size: 1.5rem;
  font-weight: bold;
  color: #e4a9a9;
}

iframe {
  pointer-events: none;
  width: 180px;
  height: 175px;
}

.events {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin-top: 1rem;
  border: 2px solid #e4a9a9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.sign-in-button {
  background-color: transparent;
  color: #dba4a4;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border: 2px solid #28a745;
  border-radius: 8px;
  cursor: pointer;
  font-family: inherit;
  margin-top: 1rem;
}

.sign-out-button {
  background-color: transparent;
  color: #dba4a4;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border: 2px solid #e25933;
  border-radius: 8px;
  cursor: pointer;
  font-family: inherit;
  margin-top: 1rem;
}

.location {
  margin-top:0.5rem;
  border-top: 1px solid #e4a9a9;
}

.prayerTime h1 {
  font-size: 1.1rem;
  font-weight: 900;
  color: #dba4a4;
}

.prayerTime h2 {
  font-size: 1rem;
  font-weight: 900;
  color: #d8b0b0;
}

.prayerTime h3 {
  font-size: 0.65rem;
  font-weight: 900;
  color: #d8b0b0;
}

.prayerTime {
  float: left;
  margin-left: 40%;
  text-align: right;
  padding-bottom: 2.5rem;
  padding-top: 1rem;
}

.timer {
  font-size: 1rem;
  font-weight: 900;
  color: #e4a9a9;
  padding-bottom: 2rem;
}

.timer h1 {
  font-size: 1.55rem;
  color: #e25933;
  font-weight: 1000;
}

.timer h2 {
  font-size: 1.35rem;
}

.timer h4 {
  font-size: 1.55rem;
  color: #a0eced;
  font-weight: 1000;
}

.App-link {
  color: #61dafb;
}

table {
  border-collapse: collapse;
  width: 100%;
  color: #e4a9a9;
}

td,
th {
  text-align: left;
  padding-left: 2rem;
  padding-right: 1rem;
  padding-bottom: 0.3rem;
}

th {
  padding-top: 1rem;
  border-bottom: 1px solid white;
  font-size: 1.25rem;
  font-weight: 900;
}

td {
  font-size: 0.9rem;
}

p {
  font-size: 0.8rem;
}

.loading {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading h2 {
  font-size: 1rem;
  color: #e4a9a9;
}

a {
  cursor: pointer;
}

.footer a:link {
  color: #e25933;
}

/* visited link */
.footer a:visited {
  color: #b005ff;
}

/* mouse over link */
a:hover {
  color: hotpink;
}

.footer {
  font-size: 0.5rem;
  padding-top: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 0.5rem;
}
